<template>
  <div id="testimonials">
    <div class="testimonial-header-container">
      <h1 id="testimonials-header">{{$t("TESTIMONIALS_HEADER")}}</h1>
    </div>
    <div id="testimonials-area">
      <div v-if="activeReview > 0" class="testimonial-adjacent testimonial-previous" :style="{ backgroundImage: `url(${userReviews[activeReview - 1].img})`}">
      </div>
      <div v-if="activeReview < userReviews.length - 1" class="testimonial-adjacent testimonial-next" :style="{ backgroundImage: `url(${userReviews[activeReview + 1].img})`}">
      </div>
    </div>
    <div class="arrows-container">
      <img src="../../../assets/arrow-left.png" class="testimonial-arrow" @click="() => activeReview > 0 && changeActiveReview(activeReview - 1)" />
      <img src="../../../assets/arrow-right.png" class="testimonial-arrow" @click="() => activeReview < userReviews.length - 1 && changeActiveReview(activeReview + 1)" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import constants from "@/utils/constants";

export default {
  name: "Testimonials",
  emits: [
      "active-testimonial-change"
  ],
  setup(props, { emit }) {
    const userReviews = ref([]);
    const activeReview = ref(0);
    const showFloat = ref(0);
    function changeActiveReview(index) {
      activeReview.value = index;
      emit('active-testimonial-change', {...userReviews.value[index], ...constants.userBoxPositions[index]})
    }
    fetch("https://static.cdwscan.com/testimonials.json").then(res => res.json()).then(response => {
      userReviews.value = response.testimonials
      emit('active-testimonial-change', {...userReviews.value[activeReview.value], ...constants.userBoxPositions[activeReview.value]})

    })
    let touchStartX = 0;
    const touchEndListener = e => {
      const scrollingRight = touchStartX - e.changedTouches[0].clientX > 10
      const scrollingLeft = touchStartX - e.changedTouches[0].clientX < -10
      if(scrollingRight ||scrollingLeft) {
        e.preventDefault()
      }
      if(scrollingLeft && activeReview.value > 0) {
        changeActiveReview(activeReview.value - 1)
      } else if(scrollingRight && activeReview.value < userReviews.value.length - 1) {
        changeActiveReview(activeReview.value + 1)
      }
    }

    const touchMoveListener = e => {
      const scrollingRight = touchStartX - e.changedTouches[0].clientX > 10
      const scrollingLeft = touchStartX - e.changedTouches[0].clientX < -10
      if(scrollingRight ||scrollingLeft) {
        e.preventDefault()
      }

    }

    onMounted(() => {
      document.getElementById("testimonials-area").addEventListener("touchmove", touchMoveListener)

      document.getElementById("testimonials-area").addEventListener("touchstart", e => {
          touchStartX = e.changedTouches[0].clientX
      })
      document.getElementById("testimonials-area").addEventListener("touchend", touchEndListener)
    })

    return {
      userReviews,
      activeReview,
      changeActiveReview,
      showFloat,
      userBoxPositions: constants.userBoxPositions
    }
  }
}
</script>

<style scoped>
#testimonials {
  width: 100%;
  height: calc(var(--oh) * 100);
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.testimonial-header-container {
  height: calc(var(--oh) * 11);
  margin-top: calc(var(--oh) * 6);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.testimonial-header-container h1 {
  font-weight: 700;
  font-size: calc(var(--oh) * 3.5);
  line-height: calc(var(--oh) * 4.3);
  color: #2E293D;
  margin: 0 10vw;
  opacity: 0;
}

.testimonial-header-container span {
  margin: calc(var(--oh) * 2) 24vw;
  font-weight: 500;
  font-size: calc(var(--oh) * 1.65);
  line-height: 150%;
  letter-spacing: 0.8px;
  color: #A7A5B2;
}
.arrows-container {
  width: calc(var(--vh) * 31.73);
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(var(--oh) * 6);
}
.testimonial-arrow {
  height: calc(var(--vh) * 6.25);
}

.testimonial-adjacent {
  position: absolute;
  top: 50%;
  height: calc(var(--oh) * 10);
  width: calc(var(--oh) * 10);
  background-size: 100% 100%;
  border-radius: 50%;
  opacity: 0.5;
  transition: all 0.35s;
}

#testimonials-area {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.testimonial-previous {
  left: 0;
  transform: translate(-50%, -50%);
}

.testimonial-next {
  right: 0;
  transform: translate(50%, -50%);
}


</style>